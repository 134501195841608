@import "stylesheets/decidim/alternative_landing/content_blocks/variables";

.stack-vertical {
  .stack {
    display: flex;

    .stack-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: $gap;
      gap: $gap;

      img {
        min-height: 15em;
        object-fit: cover;
      }

      .stack-body {
        line-height: 1.3;
      }
    }

    .stack-tags {
      margin: 0;

      li {
        margin: 0;
      }
    }

    .stack-button {
      width: 100%;
    }

    @include breakpoint(medium down) {
      flex-direction: column;
    }
  }
}
