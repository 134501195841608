@import "stylesheets/decidim/alternative_landing/content_blocks/variables";

.tiles-4 {
  .tiles {
    display: grid;
    grid-template-columns: 29% 29% 40%;
    grid-template-rows: repeat(2, 1fr);
    min-height: 100vh;
    color: white;
    gap: 1%;
    padding-bottom: $gap;
    overflow-x: hidden;
  }

  .tile {
    background-size: cover;
    background-position: center;
    background-color: rgba(255, 255, 255, 0.5);
    background-blend-mode: screen;
    min-height: 4em;
    padding: $gap;

    .tile-body {
      max-width: 14em;
      background: $alternative-color-1;
      padding: $gap;

      h2 {
        a {
          color: $header-color;
        }
      }
    }

    &-heading {
      grid-column: span 3;
      background: $alternative-color-2;
      margin: 0;
    }
    &-1 {
      grid-column: span 2;
      grid-row: 2;
    }
    &-2 {
      grid-column: 3;
      grid-row: 2 / span 2;
    }
    &-3 {
      grid-column: 1;
      grid-row: 3;
    }
    &-4 {
      grid-column: 2;
      grid-row: 3;
    }
  }
}

@include breakpoint(medium down) {
  .tiles {
    display: block;
    grid-template-columns: 29% 29% 40%;
  }

  .tile {
    min-height: 50vh;
  }
}
