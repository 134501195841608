@import "stylesheets/decidim/alternative_landing/content_blocks/variables";

@mixin cover-background-image($color, $opacity) {
  background-size: cover;
  background-position: center;
  background-blend-mode: luminosity;
  background-color: rgba($color, $opacity);
  min-height: 100vh;
}

.cover-full,
.cover-half {
  .cover-body {
    font-size: 1.25em;
    padding-top: $gap;
  }

  .cover-body p {
    padding-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
}

.cover-full {
  color: rgba(var(--color_text-rgb), var(--opacity_text));

  @include cover-background-image(var(--color_background_image-rgb), var(--opacity_background_image));

  .cover-text {
    background-color: rgba(var(--color_background_text-rgb), var(--opacity_background_text));
    padding: $gap * 4;
    width: 50%;
  }

  @include breakpoint(medium down) {
    .cover-text {
      width: 100%;
      padding: 5%;
      justify-content: center;
    }
  }

}

.cover-half {
  display: grid;
  grid-template-columns: 40em 1fr;

  .cover-image {
    grid-column: 2;

    @include cover-background-image(var(--color_background_image-rgb), var(--opacity_background_image));
  }

  @include breakpoint(medium down) {
    display: block;

    .cover-image {
      min-height: 50vh;
    }

    .cover-text {
      padding: $gap;
      width: 100%;
    }
  }
}

.navbar.transparent {
  background-color: rgba(var(--color_navbar-rgb), var(--opacity_navbar));
  position: relative;
  margin-bottom: -50px;
}

.heading1 {
  font-size: 4em;
}
